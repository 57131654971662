@media screen {

  @import 'zen-grids';
  @import 'breakpoint';

  @import 'init/colors';
  @import 'init/variables';

  .radio_item {
    //display:inline !important;
  }
  .front {

    .slide {
      
      .limiter {
        //display:flex;
      }
      
      &:after {
        content:'';
        display:block;
        clear:both;
        padding-bottom:50px;
      }
      
    }
    $zen-columns: 12 !global;
    .slide__image {
      //@include zen-grid-item(6,1);    
    } 
    .slide__caption {

      //@include zen-grid-item(6,7); 
    }
  } 

  .section-how-it-works .paragraphs-items-field-basic-content-block .field-items {          
        display:grid;
        grid-gap:2em;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom:2em;

        @include respond-to(s) {
          grid-template-columns: repeat(2, 1fr);

        }  
        @include respond-to(m) {
          grid-template-columns: repeat(3, 1fr);

        }  

        .field-items {
          display:block;
        }
          
          .field-item {
            text-align:center;
            background:none;
            padding:0;
            width:100%;
          }                
             
  }
    
  


  .no-flexbox {

	    //@include zen-respond-to(m) {
        
        .section-how-it-works {
          .paragraphs-items-field-basic-content-block {
            .field-items {          
                
              .field-item {
                $zen-columns: 12 !global;
                text-align:center;
                padding:0 10px;
              
                &:nth-child(3n+1) {
                  @include zen-grid-item(4,1); 
                }
                &:nth-child(3n+2) {
                  @include zen-grid-item(4,5); 
                }
                &:nth-child(3n+3) {
                  @include zen-grid-item(4,9); 
                }  
                &:nth-child(4n+4) {
                  @include zen-new-row()
                }   
                
                .field-item {
                  @include undo-zen-grid-item();
                  width:100%;
                  text-align:center;
                  background:none;
                  padding:0;
                }                
                   
              }
            }
          }
        }
        
        
		    .view-expert-guidance {
		      .view-content {
            
            $zen-columns: 12 !global;
            //background:#fff;
            
            &:after {
              content: '';
              display: block;
              clear: both;
            }
                    
            .views-row {
              
              padding: 0 10px;
              float: left;
              margin-right: 10px;
              margin-left: 10px;            
              margin-bottom: 10px;
              margin-top:20px;
              min-height:350px;
              height: auto !important;              
              height:350px;
              width: calc(25% - 20px);     
              box-sizing:border-box;      
                       
              &:nth-child(4n+4) {
                margin-right: 0;
              } 
                           
              .views-row {
                padding:0;
                float: none;
                margin-right: 0;
                margin-bottom: 0; 
                margin-top: 0;  
                min-height:auto;  
                width: auto;                     
              }            
            }

   
            
		      }
        }
	  //}

    .view-expert-guidance .view-content {
	    //@extend %grid;
	    //padding-top: 0;
    } 
  }

  ul.custom-tabs {

	  li {
	    float:left;
	  }
	  
  }

}
