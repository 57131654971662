@media screen {
  .front .slide:after {
    content: '';
    display: block;
    clear: both;
    padding-bottom: 50px;
  }
  .section-how-it-works .paragraphs-items-field-basic-content-block .field-items {
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 2em;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
  .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-items {
    display: block;
  }
  .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item {
    text-align: center;
    background: none;
    padding: 0;
    width: 100%;
  }
  .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item {
    text-align: center;
    padding: 0 10px;
  }
  .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(3n+1) {
    float: left;
    width: 400%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(3n+2) {
    float: left;
    width: 400%;
    margin-left: 400%;
    margin-right: -100%;
  }
  [dir="rtl"] .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(3n+2) {
    float: right;
    margin-right: 400%;
    margin-left: -100%;
  }
  .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(3n+3) {
    float: left;
    width: 400%;
    margin-left: 800%;
    margin-right: -100%;
  }
  [dir="rtl"] .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(3n+3) {
    float: right;
    margin-right: 800%;
    margin-left: -100%;
  }
  .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(4n+4) {
    clear: left;
  }
  [dir="rtl"] .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item:nth-child(4n+4) {
    clear: right;
  }
  .no-flexbox .section-how-it-works .paragraphs-items-field-basic-content-block .field-items .field-item .field-item {
    float: none;
    width: auto;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    text-align: center;
    background: none;
    padding: 0;
  }
  .no-flexbox .view-expert-guidance .view-content:after {
    content: '';
    display: block;
    clear: both;
  }
  .no-flexbox .view-expert-guidance .view-content .views-row {
    padding: 0 10px;
    float: left;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    min-height: 350px;
    height: auto !important;
    height: 350px;
    width: calc(25% - 20px);
    box-sizing: border-box;
  }
  .no-flexbox .view-expert-guidance .view-content .views-row:nth-child(4n+4) {
    margin-right: 0;
  }
  .no-flexbox .view-expert-guidance .view-content .views-row .views-row {
    padding: 0;
    float: none;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    min-height: auto;
    width: auto;
  }
  ul.custom-tabs li {
    float: left;
  }
}
